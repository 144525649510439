// This is all you.
import 'lazysizes';

import '@fontsource/inter';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

// import lazysizes from "lazysizes";
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'lazysizes/plugins/rias/ls.rias.js';

// import sal for scroll animations
import sal from 'sal.js';
sal();
